<template>
  <div class="">
    <el-table
      :data="items.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase())|| data.last_logged_out.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%"
    >
      <el-table-column
        label="#"
        type="index"
        :index="indexMethod"
      />
      <el-table-column
        label="Name"
        prop="name"
        sortable
      />
      <el-table-column
        label="Last Logged Out"
        prop="last_logged_out"
        sortable
      />
      <el-table-column
        align="right"
      >
        <template
          slot="header"
          slot-scope="scope"
        >
          <div class="table-header d-flex">

            <div class="">
              <el-badge
                :value="items.length"
                class="mt-1"
              >
                <el-button
                  type="success"
                  size="mini"
                  @click.prevent="refresh"
                >
                  Refresh
                </el-button>
              </el-badge>
            </div>
            <el-input
              v-model="search"
              class="ml-3"
              clearable
              placeholder="Type to search"
            />
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { OFFLINE_USERS } from '@core/services/api'

export default {
  data() {
    return {
      items: [],
      search: '',
      loading: true,
    }
  },
  mounted() {
    this.routerId = this.$route.params.routerId // Get the routerId from route params
    this.getOffLineData()
  },
  methods: {
    getOffLineData() {
      this.loading = true
      this.$http
        .get(process.env.VUE_APP_BASEURL + OFFLINE_USERS + this.routerId)
        .then(res => {
          this.items = res.data.data.offline_users
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.items = []
          }
        })

      this.loading = false
    },
    refresh() {
      this.getOffLineData()
    },
    indexMethod(index) {
      return index + 1
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

@mixin ledColor($primaryColor, $secondaryColor) {
  background-color: $primaryColor;
}

@mixin blackLed($dimmedColor) {
  @include ledColor($dimmedColor, $dimmedColor);
}

@mixin led($name, $primaryColor, $secondaryColor, $dimmedColor, $blinkDuration) {
  &.#{$name} {
    @keyframes blink-#{$name} {
      from {
        @include blackLed($dimmedColor);
      }
      25% {
        @include blackLed($dimmedColor);
      }
      30% {
        @include ledColor($primaryColor, $secondaryColor);
      }
      75% {
        @include ledColor($primaryColor, $secondaryColor);
      }
      80% {
        @include blackLed($dimmedColor);
      }
      to {
        @include blackLed($dimmedColor);
      }
    }

    @include ledColor($primaryColor, $secondaryColor);

    &.blink {
      animation: blink-#{$name} $blinkDuration infinite;
    }
  }
}

.led {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.25);

  &.pulse {
    animation: pulse 4s infinite;
  }

  @include led('red', #f00, #600, #ac1f1f, 2.5s);
}

</style>
<style>
/* HTML: <div class="loader"></div> */

@keyframes l5 {
  0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
  33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
  66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
  100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
}
</style>
